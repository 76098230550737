import React, { useState, useEffect } from 'react';
import style from 'styled-components';
import axios from 'axios';
import validator from 'validator';
import AppleLogo from '../../static/apple-logo-rainbow.png';
import sal from 'sal.js';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const Content = style.div`
flex: 1;
width: 80%;
margin: 0 auto;
padding: 0 100px;
@media (max-width: 768px) {
  padding: 0 10px;
}
h1 {
    opacity: .3;
    font-size: 1.2em;
    text-align: center;
    width: 300px;
    margin: 54px auto;
}
p {
  line-height: 24px;
}
a {
    color: #F14946;
}
@media (max-width: 768px) {
  width: 90%;
}
.image-with-caption {
    margin-top: 40px;
    img {
        width: 100%;
    }
    p {
      color: #000;
      opacity: .5;
      font-size: 14px;
      padding: 5px 15px;
      font-style: italic;
      margin: 0;
      font-weight: 600;
      text-align: center;
    }
}
.button-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.mac-download-button {
    border: none;
    padding: 10px 25px;
    text-decoration: none;
    background #000;
    display: inline-flex;
    font-family: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: 10px auto;
    // box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
    transition: box-shadow 200ms ease 0s, transform 200ms ease 0s, background 200ms ease 0s;
    cursor: pointer;
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.4);
    }
    &:active {
      transform: translateY(1px);
    }
    .mac-download-button-logo {
      width: 50px
    }
    .mac-download-button-text {
        margin-left: 20px;
        text-align: left;
        margin-top: 5px;
    }
    .mac-download-button-top-text {
      color: #fff;
      opacity: .9;
      font-size: 14px;
      font-weight: 700;
    }
    .mac-download-button-bottom-text {
      color: #fff;
      font-size: 34px;
      font-weight: 700;
    }
}
.mac-download-button-subtext {
  opacity: .5;
  font-size: .9em;
  text-align: center;
  width: 100%;
  margin-top: 5px;
}
.email-popup {
  background-color: #fff;
  text-align: center;
  border: 1px solid #F6F6F6;
  box-shadow: 0 20px 40px 0 rgba(0,0,0,.3);
  padding: 15px;
  position: absolute;
  left: calc(50% - 150px);
  bottom: 125px;
  width: 300px;
  transition: height 1s ease;
  z-index: 10;
  &:before {

  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    clear: both;
  }
  .email-popup-headline {
    font-size: 1.2em;
    margin: 20px 0 5px 0;
    font-weight: 700;
  }
  .email-popup-subhead {
    opacity: .5;
    font-size: .9em;
    padding: 0 5px;
  }
  .email-popup-input {
    border: none;
    border-bottom: 2px solid #000;
    padding: 12px 15px;
    width: 100%;
    font-size: 16px;
    &:focus {
      border-bottom: 2px solid #F14946;
      outline: none;
    }
    &:placeholder {
      color: #DEDEDE;
    }
  }
}
.download-now-button {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  background-color: #F14946;
  color: #fff;
  border: 2px solid #F14946;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background-color: #E02723;
  }
  &:disabled {
    background-color: #fff;
    opacity: .2;
    cursor: not-allowed;
    border: 2px solid #000;
    color: #000;
  }
}
`;

const DownloadButton = (props) => {
  const [version, setVersion] = useState('1.0.47');
  const [showPopup, setShowPopup] = useState(false);
  const [enableDownload, setEnableDownload] = useState(false);
  const [downloadStarting, setDownloadStarting] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [emailInput, setEmailInput] = useState('');

  useEffect(() => {
    const getCurrentVersion = () => {
      axios
        .get(`https://api.artdiario.com/api/version`)
        .then((res) => {
          const serverVersion = res.data.currentVersion.substring(1);
          setVersion(serverVersion);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getCurrentVersion();
  }, []);

  const firePopup = () => {
    if (showPopup === true) {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  };

  const validateEmail = (e) => {
    let email = e.target.value;

    if (validator.isEmail(email)) {
      setEnableDownload(true);
    } else {
      setEnableDownload(false);
    }
  };

  const downloadNow = () => {
    setDownloadStarting(true);
    axios
      .post(`https://api.artdiario.com/api/email`, { email: emailInput })
      .then((res) => {
        const link = document.createElement('a');
        link.id = 'downloadMac';
        link.href = `https://media.artdiario.com/app/ArtDiario-${version}.dmg`;
        link.setAttribute('download', 'download');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        trackCustomEvent({
          category: 'Download',
          action: `Completed`,
          label: version,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setDownloadStarting(false);
    }, 3000);
  };

  const enterKeyHit = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (enableDownload === true) {
        downloadNow();
      }
    }
  };

  useEffect(() => {
    if (enableDownload === true && downloadStarting === false) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [enableDownload, downloadStarting]);

  React.useEffect(() => {
    // scroll library https://github.com/mciastek/sal
    sal({
      threshold: 0.2,
    });
  }, []);

  return (
    <>
      <Content>
        <div className="flex flex-col relative">
          {showPopup ? (
            <div className="email-popup">
              <h3 className="email-popup-headline">Beta Email Signup</h3>
              <p className="email-popup-subhead">
                I'll only email 1-2 times a year when there's something
                interesting to say
              </p>
              <input
                className="email-popup-input"
                onKeyUp={(e) => enterKeyHit(e)}
                value={emailInput}
                onInput={(e) => setEmailInput(e.target.value)}
                onChange={(e) => validateEmail(e)}
                placeholder="Email Address"
                type="text"
              />
              <button
                className="download-now-button"
                onClick={() => downloadNow()}
                disabled={disabledButton}
              >
                {downloadStarting ? 'Loading...' : 'Download Now'}
              </button>
            </div>
          ) : null}
          <div
            data-sal-duration="300"
            data-sal="slide-up"
            data-sal-delay="800"
            data-sal-easing="ease-in-out"
            className="mx-auto"
          >
            <button
              className="will-change-transform border-0 px-6 py-3 bg-black rounded-xl inline-flex justify-center items-center mx-auto ease-in-out duration-200 active:translate-y-0.5 hover:-translate-y-0.5 hover:drop-shadow-button"
              onClick={() => firePopup()}
            >
              <img
                className="w-14"
                alt="Mac Download Button Logo"
                src={AppleLogo}
              />
              <div className="ml-5">
                <div className="text-sm text-slate-300 font-bold text-left">
                  Download for
                </div>
                <div className="text-slate-50 font-bold text-4xl text-left">
                  MacOS
                </div>
              </div>
            </button>
          </div>

          <div
            data-sal-duration="300"
            data-sal="fade"
            data-sal-delay="1000"
            data-sal-easing="ease-in-out"
          >
            <p className="mac-download-button-subtext">
              Currently in Beta,{' '}
              <a href="mailto:jimmy@artdiario.com">send me</a> bug reports!
            </p>
          </div>
        </div>
      </Content>
    </>
  );
};

export default DownloadButton;
